import React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 78 100"
      className="c-icon c-icon--app"
      preserveAspectRatio="xMinYMid meet"
    >
      <rect x="1" y="15" width="70" height="70" rx="12" ry="12" />
      <circle cx="64.5" cy="18.5" r="12" />
      <circle cx="64.5" cy="18.5" r="7" />
    </svg>
  )
}
